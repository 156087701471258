<template>
  <div class="menu-role-permissions">
    <v-card-actions class="pa-0">
      <h2 class="primary--text">{{ title.name ? title.name : title }}</h2>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-spacer></v-spacer>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-col cols="6" sm="12" md="4" class="pa-0">
        <SearchField
          :search-function="filterData"
          :prepend-icon="'mdi-magnify'"
          :label="'Search Roles'"
          :append-icon="''"
        />
      </v-col>
    </v-card-title>

    <v-card>
      <div>
        <v-row class="pl-5">
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-select
              label="Permission Slug"
              :items="slugList"
              :item-text="'name'"
              v-model="slug"
              @change="loadMenusOrRoles($event)"
              return-object
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-autocomplete
              :label="title.label ? title.label : 'Select '"
              :items="menuOrRoles"
              :item-text="'name'"
              v-model="selected"
              @change="getPermissions"
              return-object
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <span v-if="selected && selected.name">
          <v-card-actions class="pa-0">
            <h3 class="pl-5 primary--text">
              <b>
                Manage Permissions for {{ slug.name }}: ({{ selected.name }})
              </b>
            </h3>
            <v-spacer></v-spacer>
            <div class="text-center d-flex pb-4">
              <v-btn @click="all" color="primary" class="white--text mr-3">
                <v-icon>mdi-arrow-expand-all</v-icon>
                Expand all
              </v-btn>

              <v-btn @click="none" color="primary" class="white--text mr-3">
                <v-icon>mdi-magnify-minus</v-icon>
                expand none
              </v-btn>
            </div>
          </v-card-actions>
        </span>

        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel v-for="(perms, index) in permissions" :key="index">
            <v-expansion-panel-header>
              <b>{{ capitalizeEach(perms.name) }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-item-group>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(item, i) in perms.permissions"
                      :key="i"
                      cols="12"
                      :md="3"
                    >
                      <v-item>
                        <v-card width="190" outlined>
                          <v-list-item>
                            <div class="overline mb-0">
                              <b>{{ capitalizeEach(item.action_name) }}</b>
                            </div>
                          </v-list-item>
                          <hr />
                          <v-card-actions>
                            <v-col cols="12" lg="12" md="12" sm="12">
                              <v-radio-group
                                class="pt-0 pb-0"
                                v-model="item.hasPermissions"
                                id="item.id"
                                :value="item.id"
                              >
                                <v-layout align-start row>
                                  <v-radio
                                    id="item.id"
                                    label="Enable"
                                    color="primary"
                                    :value="true"
                                    @change="addPermission(item, (data = true))"
                                  ></v-radio>
                                  <v-radio
                                    id="item.id"
                                    label="Disable"
                                    :value="false"
                                    color="red"
                                    @change="
                                      addPermission(item, (data = false))
                                    "
                                  ></v-radio>
                                </v-layout>
                              </v-radio-group>
                            </v-col>
                          </v-card-actions>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <div class="pa-7">
          <v-btn
            color="primary white--text"
            @click="savePermissions"
            :disabled="!selected"
          >
            <v-icon>mdi-content-save-all-outline</v-icon>
            Save
          </v-btn>
        </div>
      </v-card-actions>
      <!-- // :disabled="!selected || selected.permissions.length == 0" -->
    </v-card>

    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import { chain, orderBy } from "lodash";

import {
  get,
  create,
  update,
  remove,
} from "../../components/menus/main/services";
import { get as fetchPermissions } from "../../components/permissions/services";
import { get as fetchMenus } from "../../components/menus/sub-menus/services";
import { get as fetchRoles } from "../../components/roles/services";
import { createRolePermissions, createMenuPermissions } from "./services";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {},
  name: "Role",
  data() {
    return {
      permissions: [],
      menuOrRoles: [],
      panel: [],
      selected: null,
      isChecked: false,
      disabled: true,
      lable: null,
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      slug: null,
      slugList: [],
      item: null,
      chosenAction: null,
      items: [],
      perms: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;
        this.params.slug = this.slug.id;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
    title() {
      // return 'Manage' + this.formData.slug && this.formData.slug.name
      //   ? this.formData.slug.name
      //   : '' + 'Permissions'
      if (this.formData && this.slug) {
        //this.label = 'Select' + this.slug.name + 's'
        return {
          name: "Manage  " + this.slug.name + " Permissions",
          label: "Select  " + this.slug.name + "s",
        };
      } else {
        return "Manage Permissions";
      }
    },
    preparedPermissions() {
      return this.perms.map((item) => ({
        ...item,
        //id:item.id
        hasPermissions: this.selected.permissions.find(
          (data) => data.id == item.id
        ),
      }));
    },
    finalPermissions() {
      return this.preparedPermissions.map((item) => ({
        ...item,
        hasPermissions: item.hasPermissions !== undefined ? true : false,
      }));
    },
  },

  methods: {
    init(params) {
      get({
        ...params,
      }).then((response) => {
        // console.log('resp', response.data[0].total)
        this.params.total = response.data[0].total;
        this.items = response.data[0]
          ? response.data[0].data
              .map((option) => ({
                ...option,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },
    getPermissions(data) {
      let params = {
        size: 100,
        search: data && data.search ? data.search : null,
      };
      fetchPermissions(params).then((response) => {
        this.perms = response.data[0].data;
        let results = chain(this.finalPermissions)
          .groupBy("resource")
          .map((permissions, name) => ({
            permissions: orderBy(permissions, "action_name", "asc"),
            name,
          }))
          .value();
        this.permissions = results;
      });
    },
    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    filterData(optionName) {
      this.getPermissions({
        search: optionName ? optionName : null,
      });
    },
    searchRoles(data) {
      this.filterData(data);
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },
    slugLists() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "PERMISSION_SLUG")
        .then((response) => {
          this.slugList = response;
        });
    },
    loadMenusOrRoles(data) {
      this.permissions = [];
      this.selected = null;
      this.chosenAction = data.name;
      if (data.name == "Menu") {
        fetchMenus({ size: 100 }).then((response) => {
          this.menuOrRoles = response.data[0].data;
        });
      } else {
        fetchRoles({ size: 100 }).then((response) => {
          this.menuOrRoles = response.data[0].data;
        });
      }
    },
    capitalizeEach(text) {
      if (!text) return text;
      if (typeof text !== "string") throw "invalid argument";

      return text
        .toLowerCase()
        .split(" ")
        .map((value) => {
          return value.charAt(0).toUpperCase() + value.substring(1);
        })
        .join(" ");
    },

    addPermission(item, data) {
      // console.log('rolePerm', item, 'data is ', data)
      let el = this.selected.permissions.find((entry) => entry.id === item.id);

      if (el && data == false) {
        const index = this.selected.permissions.findIndex(
          (entry) => entry.id === item.id
        );

        this.selected.permissions.splice(index, 1);
      } else {
        if (data == true) {
          this.selected.permissions.push(item);
        }
      }
    },

    savePermissions() {
      this.chosenAction === "Menu"
        ? createMenuPermissions(this.selected)
        : createRolePermissions(this.selected);
    },
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panel = [...Array(this.permissions.length).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },
  },

  watch: {},

  mounted() {
    this.slugLists();
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
